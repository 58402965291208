import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Testimonial from "../sections/index/indexreviews.js";
import Contentsmile from "../sections/smile/smilecontent.js";
import SmileFaq from "../sections/smile/smilefaq.js";
import Head from "../components/head";
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/smile-lines.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const Smileline = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
    <Head title="Smile Line Treatment UK" image="/images/dermamina-home.jpg"  description="Experts in Smile Line treatments London" keywords="smile line treatment, nasolabial fold fillers, smile line fillers, facial wrinkle treatment, dermal fillers for smile lines, non-surgical smile line treatment, nasolabial wrinkle reduction, anti-ageing smile line treatment, cosmetic smile line fillers, smile line correction"/>
<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Smile </span> <span style="color: #00aec7;">Lines</span>'
    secondText="Soften, restore and refresh your look"
    videoAlt="Smile Lines"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Smile Lines"
  resultstext="12 - 18 months"
  resultsalt="Smile Lines results"
  costdata="From £270"
  costalt="Smile Lines Cost"
  timedata="10 Minutes"
  timealt="Smile Lines Duration Time"
  workdata="Immediately"
  workalt="Smile Lines downtime work"
  paindata="None / Mild"
  painalt="Smile Lines Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Testimonial />
<Contentsmile
  setIsModalOpen={setIsModalOpen}
/>
<SmileFaq
  setIsModalOpen={setIsModalOpen}
/>
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>
    </>
  );
};
export default Smileline;
